<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
  import { IonApp, IonRouterOutlet, IonButton } from '@ionic/vue';
  import { defineComponent } from 'vue';
  export default defineComponent({
    name: 'App',
    components: {
      IonApp,
      IonRouterOutlet
    },
    data() {
      return {

      }
    }
  });
</script>

<style>

table tr th,td {
  text-align: center;
  padding: 12px 9px;
}

</style>