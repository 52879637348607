<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">

        <ion-tab-button tab="tab5" href="/tabs/tab5">
          <ion-icon :icon="people" />
          <ion-label>Mijozlar</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab2" href="/tabs/tab2">
          <ion-icon :icon="statsChart" />
          <ion-label>Statistika</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab4" href="/tabs/tab4">
          <ion-icon :icon="storefrontOutline" />
          <ion-label>Ombor</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab1" href="/tabs/orders">
          <ion-icon :icon="swapHorizontalOutline" />
          <ion-label>Savdolar</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab3" href="/tabs/tab1">
          <ion-icon :icon="print" />
          <ion-label>Kassa</ion-label>
        </ion-tab-button>

      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
import { people, print, statsChart, swapHorizontalOutline, storefrontOutline, ellipse, square, triangle, phonePortraitOutline, personOutline, alertCircleOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'TabsPage',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet },
  setup() {
    return {
      print,
      statsChart,
      ellipse,
      people,
      square, 
      triangle,
      phonePortraitOutline,
      personOutline,
      alertCircleOutline,
      storefrontOutline,
      swapHorizontalOutline
    }
  }
});
</script>

<style scoped>

  ion-tab-bar {
    background: #5260ff;
  }

  ion-tab-button {
    color: white;
    background: #5260ff;
  }

</style>