<template>
  <nav aria-label="Page navigation example" class="d-flex justify-content-center mb-5 align-items-center column-gap-3" style="height: 34px;">
    <ul class="pagination" style="height: inherit;">
      <li class="page-item"><a style="cursor:pointer;font-size:14px;color: #4d4d4d;" class="page-link" @click="fetchPreviousPage"><img :src="left" alt=""> Avvalgi</a></li>
      <li class="page-item"><a style="font-size:14px;color: #4d4d4d;" class="page-link"><span v-if="!loading">{{ currentPage }}</span><img height="20" v-else :src="spinner" alt=""></a></li>
      <li class="page-item"><a style="cursor:pointer;font-size:14px;color: #4d4d4d;" class="page-link" @click="fetchNextPage">Keyingi <img :src="right" alt=""></a></li>
    </ul>
    <p style="font-size: 14px;">
      Jami: {{total}} ta
    </p>
  </nav>
</template>

<script>
import left from '@/assets/left.svg';
import right from '@/assets/right.svg';
import spinner from '@/assets/spinner.gif';

export default {
  name: 'Paginate',
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
    fetchPreviousPage: {
      type: Function,
      required: true,
    },
    fetchNextPage: {
      type: Function,
      required: true,
    },
  },
  setup() {

    return {
      left,
      right,
      spinner,
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped>
/* You can add custom styles for the button here */
</style>
