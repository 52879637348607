import { createStore } from 'vuex';
import axios from 'axios';
import createPersistedState from 'vuex-persistedstate';
import { toastController } from '@ionic/vue';


export default createStore({
    plugins: [createPersistedState()],
    state: {
        access_token: undefined,
        phone: undefined,
        password: undefined,
        URL: "https://api.savdopro.uz/public/api",
        loading: false
    },
    mutations: {
        setAccessToken(state, token) {
            state.access_token = token;

        },
        setPhone(state, phone) {
            state.phone = phone;
        },
        setPassword(state, password) {
            state.password = password;
        }
    },
    actions: {
        // eslint-disable-next-line no-unused-vars
        async presentToast({commit}, message) {
            const toast = await toastController.create({
                message: message,
                duration: 1500,
                position: "bottom",
            });

            await toast.present();
        },
        logOut({ commit }, ROUTER) {
            commit('setAccessToken', undefined);
            commit('setPassword', undefined);
            ROUTER.push('/tabs/tab2');
        },
        // eslint-disable-next-line no-unused-vars
        async fetchData({ commit, dispatch, state, getters }, { ENDPOINT, FORM, ROUTER }) {

            try {

                if (!window.navigator.onLine) { alert('Internet bilan aloqa uzilgan!'); return }

                state.loading = true;
                const response = await axios.post(state.URL + ENDPOINT, FORM, { headers: getters.getHeaders });
                const result = response.data;

                state.loading = false;

                if (response.status === 401) {
                    dispatch('logOut', ROUTER);
                } else if (result.status === "error") {
                    alert(result.data);
                } else if (result.status === "ok" && ENDPOINT !== "/texnozap/save-credentials") {
                    return result.data;
                }

                return result;
            } catch (error) {

                state.loading = false;

                if (error.response && error.response.status === 401) {
                    dispatch('logOut', ROUTER);
                } else if (error.message === "Network Error") {
                    alert("Internet bilan muammo!");
                }
            }
        }
    },
    getters: {
        loading: (state) => {
            return state.loading;
        },
        total_order_items: (state) => {
            return state.total_order_items;
        },
        getHeaders: (state, getters) => {
            const headers = {
                'Accept': 'application/json',
                'Authorization': `Bearer ${getters.getAccessToken}`
            }
            return headers;
        },
        getPhone: (state) => {
            return state.phone;
        },
        getPassword: (state) => {
            return state.password;
        },
        getAccessToken: (state) => {
            return state.access_token;
        }
    },
});
