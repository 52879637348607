import { createRouter, createWebHistory } from '@ionic/vue-router';
import TabsPage from '../views/TabsPage.vue'
// import store from '../store';
// import { Storage } from '@ionic/storage';
// import LoginPage from '@/views/Login.vue'; // Import the Login component

const routes = [
  // {
  //   path: '/',
  //   redirect: '/login'
  // },
  // {
  //   path: '/login',
  //   component: LoginPage
  // },
  {
    path: '/',
    redirect: '/tabs/tab1'
  },
  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/tabs/tab1'
      },
      {
        path: 'tab1',
        component: () => import('@/views/Tab1Page.vue')
      },
      {
        path: 'orders',
        component: () => import('@/views/Orders.vue')
      },
      {
        path: 'order-items/:id',
        component: () => import('@/views/OrderItem.vue')
      },
      {
        path: 'store-items/:store_title',
        component: () => import('@/views/StoreItems.vue')
      },
      {
        path: 'tab2',
        component: () => import('@/views/BarChart.vue')
      },
      {
        path: 'tab5',
        component: () => import('@/views/Clients.vue')
      },
      {
        path: 'tab3',
        component: () => import('@/views/Tab3Page.vue')
      },
      {
        path: 'tab4',
        component: () => import('@/views/Tab4Page.vue')
      },
      {
        path: 'tab-add',
        component: () => import('@/views/TabAdd.vue')
      },
      {
        path: 'tab-add-store',
        component: () => import('@/views/TabAddStore.vue')
      },
      {
        path: 'tab-add-client',
        component: () => import('@/views/TabAddClient.vue')
      },
      {
        path: 'single/:id',
        component: () => import('@/views/SinglePage.vue')
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory('/'),
  routes
});
//
// router.beforeEach((to, from, next) => {
//   const isAuthenticated = localStorage.getItem('at');
//   if (to.path !== '/login' && !isAuthenticated) {
//     next('/login');
//   } else {
//     next();
//   }
// });

export default router;
